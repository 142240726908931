import React from "react";

const HighlightedContent = () => {
  const blueHighlight: string = "bg-indigo-200";
  const yellowHighlight: string = "bg-yellow-400";

  return (
    <>
      <div className="">

        <div className="mt-5">
          <h2 className="font-bold">The Basics</h2>
          <p className="">The NIH Clinical Trials and You website is a resource for people who want to learn more about clinical trials. The information below answers to common questions about taking part in a clinical trial.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What are clinical trials and why do people participate?</span></h2>
          <p className="">Clinical research is medical research that involves people like you. When you volunteer to take part in clinical research, you help doctors and researchers learn more about disease and improve health care for people in the future. <span className={yellowHighlight}>Clinical research includes all research that involves people.</span></p>

          <p>Types of clinical research include:</p>
          <ul className="ml-8 list-disc">
            <li>Epidemiology, which improves the understanding of a disease by studying patterns, causes, and effects of health and disease in specific groups.</li>
            <li>Behavioral, which improves the understanding of human behavior and how it relates to health and disease.</li>
            <li>Health services, which looks at how people access health care providers and health care services, how much care costs, and what happens to patients as a result of this care.</li>
            <li>Clinical trials, which evaluate the effects of an intervention on health outcomes.</li>
          </ul>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What are clinical trials and why would I want to take part?</span></h2>
          <p className="">Clinical trials are part of clinical research and at the heart of all medical advances. <span className={yellowHighlight}>Clinical trials look at new ways to prevent, detect, or treat disease.</span></p>

          <p>Clinical trials can study:</p>
          <ul className="ml-8 list-disc">
            <li>New drugs or new combinations of drugs</li>
            <li>New ways of doing surgery</li>
            <li>New medical devices</li>
            <li>New ways to use existing treatments</li>
            <li>New ways to change behaviors to improve health</li>
            <li>New ways to improve the quality of life for people with acute or chronic illnesses.</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className=""><span className={yellowHighlight}>The goal of clinical trials is to determine if these treatment, prevention, and behavior approaches are safe and effective.</span> People take part in clinical trials for many reasons. Healthy volunteers say they take part to help others and to contribute to moving science forward. People with an illness or disease also take part to help others, but also to possibly receive the newest treatment and to have added (or extra) care and attention from the clinical trial staff. Clinical trials offer hope for many people and a chance to help researchers find better treatments for others in the future.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>How does the research process work?</span></h2>
          <p className="">The idea for a clinical trial often starts in the lab. <span className={yellowHighlight}>After researchers test new treatments or procedures in the lab and in animals, the most promising treatments are moved into clinical trials. As new treatments move through a series of steps called phases, more information is gained about the treatment, its risks, and its effectiveness.</span></p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What are clinical trial protocols?</span></h2>
          <p className=""><span className={yellowHighlight}>Clinical trials follow a plan known as a protocol. The protocol is carefully designed to balance the potential benefits and risks to participants, and answer specific research questions.</span></p>

          <p>A protocol describes the following:</p>
          <ul className="ml-8 list-disc">
            <li>The goal of the study</li>
            <li>Who is eligible to take part in the trial</li>
            <li>Protections against risks to participants</li>
            <li>Details about tests, procedures, and treatments</li>
            <li>How long the trial is expected to last</li>
            <li>What information will be gathered</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="">A clinical trial is led by a principal investigator (PI). Members of the research team regularly monitor the participants’ health to determine the study’s safety and effectiveness.</p> </div>
        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What is an Institutional Review Board?</span></h2>
          <p className=""><span className={yellowHighlight}>Most, but not all, clinical trials in the United States are approved and monitored by an Institutional Review Board (IRB) to ensure that the risks are reduced and are outweighed by potential benefits. IRBs are committees that are responsible for reviewing research in order to protect the rights and safety of people who take part in research, both before the research starts and as it proceeds.</span> You should ask the sponsor or research coordinator whether the research you are thinking about joining was reviewed by an IRB.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold">What is a clinical trial sponsor?</h2>
          <p className="">Clinical trial sponsors may be people, institutions, companies, government agencies, or other organizations that are responsible for initiating, managing or financing the clinical trial, but do not conduct the research.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What is informed consent?</span></h2>
          <p className=""><span className={yellowHighlight}>Informed consent is the process of providing you with key information about a research study before you decide whether to accept the offer to take part. The process of informed consent continues throughout the study.</span> To help you decide whether to take part, members of the research team explain the details of the study. If you do not understand English, a translator or interpreter may be provided. The research team provides an informed consent document that includes details about the study, such as its purpose, how long it’s expected to last, tests or procedures that will be done as part of the research, and who to contact for further information. The informed consent document also explains risks and potential benefits. You can then decide whether to sign the document. <span className={yellowHighlight}>Taking part in a clinical trial is voluntary and you can leave the study at any time.</span></p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold">What are the types of clinical trials?</h2>
          <p className="">There are different types of clinical trials.</p>
          <ul className="ml-8 list-disc">
            <li><span className="font-bold">Prevention trials </span>look for better ways to prevent a disease in people who have never had the disease or to prevent the disease from returning. Approaches may include medicines, vaccines, or lifestyle changes.</li>
            <li><span className="font-bold">Screening trials </span>test new ways for detecting diseases or health conditions.</li>
            <li><span className="font-bold">Diagnostic trials </span>study or compare tests or procedures for diagnosing a particular disease or condition.</li>
            <li><span className="font-bold">Treatment trials </span>test new treatments, new combinations of drugs, or new approaches to surgery or radiation therapy.</li>
            <li><span className="font-bold">Behavioral trials </span>evaluate or compare ways to promote behavioral changes designed to improve health.</li>
            <li><span className="font-bold">Quality of life trials </span>(or supportive care trials) explore and measure ways to improve the comfort and quality of life of people with conditions or illnesses.</li>
          </ul>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What are the phases of clinical trials?</span></h2>
          <p>Clinical trials are conducted in a series of steps called “phases.” <span className={yellowHighlight}>Each phase has a different purpose and helps researchers answer different questions.</span></p>
          <ul className="ml-8 list-disc">
            <li><span className="font-bold">Phase I trials: </span>Researchers test a drug or treatment in a small group of people (20–80) for the first time. The purpose is to study the drug or treatment to learn about safety and identify side effects.</li>
            <li><span className="font-bold">Phase II trials: </span>The new drug or treatment is given to a larger group of people (100–300) to determine its effectiveness and to further study its safety.</li>
            <li><span className="font-bold">Phase III trials: </span>The new drug or treatment is given to large groups of people (1,000–3,000) to confirm its effectiveness, monitor side effects, compare it with standard or similar treatments, and collect information that will allow the new drug or treatment to be used safely.</li>
            <li><span className="font-bold">Phase IV trials: </span>After a drug is approved by the FDA and made available to the public, researchers track its safety in the general population, seeking more information about a drug or treatment’s benefits, and optimal use.</li>
          </ul>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What do the terms placebo, randomization, and blinded mean in clinical trials?</span></h2>
          <p className=""><span className={yellowHighlight}>In clinical trials that compare a new product or therapy with another that already exists, researchers try to determine if the new one is as good, or better than, the existing one. In some studies, you may be assigned to receive a placebo (an inactive product that resembles the test product, but without its treatment value).</span></p>
        </div>

        <div className="mt-5">
          <p className="">Comparing a new product with a placebo can be the fastest and most reliable way to show the new product’s effectiveness. However, <span className={yellowHighlight}>placebos are not used if you would be put at risk — particularly in the study of treatments for serious illnesses — by not having effective therapy.</span> You will be told if placebos are used in the study before entering a trial.</p>
        </div>

        <div className="mt-5">
          <p className=""><span className={yellowHighlight}>Randomization is the process by which treatments are assigned to participants by chance rather than by choice.</span> This is done to avoid any bias in assigning volunteers to get one treatment or another. The effects of each treatment are compared at specific points during a trial. If one treatment is found superior, the trial is stopped so that the most volunteers receive the more beneficial treatment.</p>
        </div>

        <div className="mt-5">
          <p className=""><span className={yellowHighlight}>'Blinded' (or 'masked') studies are designed to prevent members of the research team and study participants from influencing the results.</span> Blinding allows the collection of scientifically accurate data. <span className={yellowHighlight}>In single-blind ('single-masked') studies, you (the participant in the study) are not told what is being given, but the research team knows. In a double-blind study, neither you nor the research team are told what you are given; only the pharmacist knows.</span> Members of the research team are not told which participants are receiving which treatment, in order to reduce bias. <span className={yellowHighlight}>If medically necessary, however, it is always possible to find out which treatment you are receiving.</span></p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>Who takes part in clinical trials?</span></h2>
          <p className="">Many different types of people take part in clinical trials. <span className={yellowHighlight}>Some are healthy, while others may have illnesses.</span> Research procedures with healthy volunteers are designed to develop new knowledge, not to provide direct benefit to those taking part. Healthy volunteers have always played an important role in research.</p>
        </div>

        <div className="mt-5">
          <p className="">Healthy volunteers are needed for several reasons. <span className={yellowHighlight}>When developing a new technique, such as a blood test or imaging device, healthy volunteers help define the limits of 'normal'.</span> These volunteers are the baseline against which patient groups are compared and are often matched to patients on factors such as age, gender, or family relationship. They receive the same tests, procedures, or drugs the patient group receives. Researchers learn about the disease process by comparing the patient group to the healthy volunteers.</p>
        </div>

        <div className="mt-5">
          <p className="">Factors like how much of your time is needed, discomfort you may feel, or risk involved depends on the trial. While some require minimal amounts of time and effort, other studies may require a major commitment of your time and effort, and may involve some discomfort. The research procedure(s) may also carry some risk. The informed consent process for healthy volunteers includes a detailed discussion of the study's procedures and tests and their risks.</p>
        </div>

        <div className="mt-5">
          <p className=""><span className={yellowHighlight}>A patient volunteer has a known health problem and takes part in research to better understand, diagnose, or treat that disease or condition.</span> Research with a patient volunteer helps develop new knowledge. Depending on the stage of knowledge about the disease or condition, these procedures may or may not benefit the study participants.</p>
        </div>

        <div className="mt-5">
          <p className="">Patients may volunteer for studies similar to those in which healthy volunteers take part. These studies involve drugs, devices, or treatments designed to prevent, or treat disease. Although these studies may provide direct benefit to patient volunteers, the main aim is to prove, by scientific means, the effects and limitations of the experimental treatment. Therefore, some patient groups may serve as a baseline for comparison by not taking the test drug, or by receiving test doses of the drug large enough only to show that it is present, but not at a level that can treat the condition.</p>
        </div>

        <div className="mt-5">
          <p className=""><span className={yellowHighlight}>Researchers follow clinical trials guidelines when deciding who can participate, in a study. These guidelines are called Inclusion/Exclusion Criteria. Factors that allow you to take part in a clinical trial are called 'inclusion criteria.' Those that exclude or prevent participation are 'exclusion criteria.'</span> These criteria are based on factors such as age, gender, the type and stage of a disease, treatment history, and other medical conditions. Before joining a clinical trial, you must provide information that allows the research team to determine whether or not you can take part in the study safely. Some research studies seek participants with illnesses or conditions to be studied in the clinical trial, while others need healthy volunteers. <span className={yellowHighlight}>Inclusion and exclusion criteria are not used to reject people personally. Instead, the criteria are used to identify appropriate participants and keep them safe, and to help ensure that researchers can find new information they need.</span></p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What do I need to know if I am thinking about taking part in a clinical trial?</span></h2>
          <p className="underline">Risks and potential benefits</p>

          <p>Clinical trials may involve risk, as can routine medical care and the activities of daily living. <span className={yellowHighlight}>When weighing the risks of research, you can think about these important factors:</span></p>
          <ul className="ml-8 list-disc">
            <li><span className={yellowHighlight}>The possible harms that could result from taking part in the study</span></li>
            <li><span className={yellowHighlight}>The level of harm</span></li>
            <li><span className={yellowHighlight}>The chance of any harm occurring</span></li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="">Most clinical trials pose the risk of minor discomfort, which lasts only a short time. However, some study participants experience complications that require medical attention. In rare cases, participants have been seriously injured or have died of complications resulting from their participation in trials of experimental treatments. <span className={yellowHighlight}>The specific risks associated with a research protocol are described in detail in the informed consent document, which participants are asked to consider and sign before participating in research. Also, a member of the research team will explain the study and answer any questions about the study.</span> Before deciding to participate, carefully consider risks and possible benefits.</p>
        </div>

        <div className="mt-5">
          <p className="underline">Potential benefits</p>
          <p>Well-designed and well-executed clinical trials provide the best approach for you to:</p>
          <ul className="ml-8 list-disc">
            <li>Help others by contributing to knowledge about new treatments or procedures.</li>
            <li>Gain access to new research treatments before they are widely available.</li>
            <li>Receive regular and careful medical attention from a research team that includes doctors and other health professionals.</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="underline">Risks</p>
          <p>Risks to taking part in clinical trials include the following:</p>
          <ul className="ml-8 list-disc">
            <li>There may be unpleasant, serious, or even life-threatening effects of experimental treatment.</li>
            <li>The study may require more time and attention than standard treatment would, including visits to the study site, more blood tests, more procedures, hospital stays, or complex dosage schedules.</li>
          </ul>
        </div>

        <div className="mt-5">
          <h2 className="font-bold">What questions should I ask if offered a clinical trial?</h2>
          <p className="underline">The study</p>
        </div>

        <div className="mt-5">
          <ul className="ml-8 list-disc">
            <li>What is the purpose of the study?</li>
            <li>Why do researchers think the approach may be effective?</li>
            <li>Who will fund the study?</li>
            <li>Who has reviewed and approved the study?</li>
            <li>How are study results and safety of participants being monitored?</li>
            <li>How long will the study last?</li>
            <li>What will my responsibilities be if I take part?</li>
            <li>Who will tell me about the results of the study and how will I be informed?</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="underline">Risks and possible benefits</p>
          <ul className="ml-8 list-disc">
            <li>What are my possible short-term benefits?</li>
            <li>What are my possible long-term benefits?</li>
            <li>What are my short-term risks, and side effects?</li>
            <li>What are my long-term risks?</li>
            <li>What other options are available?</li>
            <li>How do the risks and possible benefits of this trial compare with those options?</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="underline">Participation and care</p>
          <ul className="ml-8 list-disc">
            <li>What kinds of therapies, procedures and/or tests will I have during the trial?</li>
            <li>Will they hurt, and if so, for how long?</li>
            <li>How do the tests in the study compare with those I would have outside of the trial?</li>
            <li>Will I be able to take my regular medications while taking part in the clinical trial?</li>
            <li>Where will I have my medical care?</li>
            <li>Who will be in charge of my care?</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="underline">Personal issues</p>
          <ul className="ml-8 list-disc">
            <li>How could being in this study affect my daily life?</li>
            <li>Can I talk to other people in the study?</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="underline">Cost issues</p>
          <ul className="ml-8 list-disc">
            <li>Will I have to pay for any part of the trial such as tests or the study drug?</li>
            <li>If so, what will the charges likely be?</li>
            <li>What is my health insurance likely to cover?</li>
            <li>Who can help answer any questions from my insurance company or health plan?</li>
            <li>Will there be any travel or child care costs that I need to consider while I am in the trial?</li>
            <li>Will there be any travel or child care costs that I need to consider while I am in the trial?</li>
          </ul>
        </div>

        <div className="mt-5">
          <p className="underline">Tips for asking your doctor about trials</p>
          <ul className="ml-8 list-disc">
            <li>Consider taking a family member or friend along for support and for help in asking questions or recording answers.</li>
            <li>Plan what to ask — but don't hesitate to ask any new questions.</li>
            <li>Write down questions in advance to remember them all.</li>
            <li>Write down the answers so that they’re available when needed.</li>
            <li>Ask about bringing a tape recorder to make a taped record of what's said (even if  you write down answers).</li>
          </ul>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>How is my safety protected?</span></h2>
          <p className="underline">Ethical guidelines</p>
          <p>The goal of clinical research is to develop knowledge that improves human health or increases understanding of human biology. People who take part in clinical research make it possible for this to occur. The path to finding out if a new drug is safe or effective is to test it on patients in clinical trials. <span className={yellowHighlight}>The purpose of ethical guidelines is both to protect patients and healthy volunteers, and to preserve the integrity of the science.</span></p>
        </div>

        <div className="mt-5">
          <p className="underline">Informed consent</p>
          <p><span className={yellowHighlight}>Informed consent is the process of learning the key facts about a clinical trial before deciding whether to participate.</span> The process of providing information to participants continues throughout the study. To help you decide whether to take part, members of the research team explain the study. The research team provides an informed consent document, which includes such details about the study as its purpose, duration, required procedures, and who to contact for various purposes. <span className={yellowHighlight}>The informed consent document also explains risks and potential benefits.</span>  <span className={yellowHighlight}>If you decide to enroll in the trial, you will need to sign the informed consent document. You are free to withdraw from the study at any time.</span></p>
        </div>

        <div className="mt-5">
          <p className="underline">IRB review</p>
          <p><span className={yellowHighlight}>Most, but not all, clinical trials in the United States are approved and monitored by an Institutional Review Board (IRB) to ensure that the risks are minimal when compared with potential benefits. An IRB is an independent committee that consists of physicians, statisticians, and members of the community who ensure that clinical trials are ethical and that the rights of participants are protected.</span> You should ask the sponsor or research coordinator whether the research you are considering participating in was reviewed by an IRB.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold"><span className={blueHighlight}>What happens after a clinical trial is completed?</span></h2>
          <p className="">After a clinical trial is completed, the researchers carefully examine information collected during the study before making decisions about the meaning of the findings and about the need for further testing. <span className={yellowHighlight}>After a phase I or II trial, the researchers decide whether to move on to the next phase or to stop testing the treatment or procedure because it was unsafe or not effective. When a phase III trial is completed, the researchers examine the information and decide whether the results have medical importance.</span></p>
        </div>

        <div className="mt-5">
          <p className="">Results from clinical trials are often published in peer-reviewed scientific journals. <span className={yellowHighlight}>Peer review is a process by which experts review the report before it is published to ensure that the analysis and conclusions are sound.</span> If the results are particularly important, they may be featured in the news, and discussed at scientific meetings and by patient advocacy groups before or after they are published in a scientific journal. Once a new approach has been proven safe and effective in a clinical trial, it may become a new standard of medical practice.</p>
        </div>

        <div className="mt-5">
          <p className="">Ask the research team members if the study results have been or will be published. Published study results are also available by searching for the study's official name or Protocol ID number in the National Library of Medicine's PubMed® database.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold">How does clinical research make a difference to me and my family?</h2>
          <p className="">Only through clinical research can we gain insights and answers about the safety and effectiveness of treatments and procedures. Groundbreaking scientific advances in the present and the past were possible only because of participation of volunteers, both healthy and those with an illness, in clinical research. Clinical research requires complex and rigorous testing in collaboration with communities that are affected by the disease. As research opens new doors to finding ways to diagnose, prevent, treat, or cure disease and disability, clinical trial participation is essential to help us find the answers.</p>
        </div>

        <div className="mt-5">
          <h2 className="font-bold">Further reading</h2>
          <p>For more information about research protections, see:</p>
          <ul className="ml-8 list-disc">
            <li><span className="underline">Office of Human Research Protection(link is external)</span></li>
            <li><span className="underline">Children's Assent to Clinical Trial Participation</span></li>
          </ul>
        </div>

        <div className="mt-5">
          <p>For more information on participants’ privacy and confidentiality, see:</p>
          <ul className="ml-8 list-disc">
            <li><span className="underline">HIPAA Privacy Rule(link is external)</span></li>
            <li><span className="underline">The Food and Drug Administration, FDA’s Drug Review Process: Ensuring Drugs Are Safe and Effective(link is external)</span></li>
          </ul>
        </div>

        <div className="mt-5">
          <p>For more information about research protections, see:</p>
          <ul className="ml-8 list-disc">
            <li><span className="underline">About Research Participation(link is external)</span></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HighlightedContent

